<script lang="ts">
  import Panel from './Panel.svelte';
  import H2 from './H2.svelte';
  import P from './P.svelte';
</script>

<Panel alwaysColumn>
  <H2 className="italic">👋 Hi! 👋</H2>
  <P className="mx-auto max-w-7xl">
    I'm Cam, a software developer and consultant from Melbourne, Australia. I
    love writing code, building great products with great people, and sharing my
    knowledge through writing, speaking, and consulting. Read on if you'd like
    to know more about my work and my skills. Or get in touch via one of the
    links above.
  </P>
</Panel>
