<script lang="ts">
  import SkillBox from '../SkillBox.svelte';
  import Skill from '../Skill.svelte';
</script>

<SkillBox title="Practices & people&nbsp;skills">
  <Skill name="Practices">
    I'm an advocate for XP practices like pair programming and test-driven
    development. Whether I'm working at a start-up or a large enterprise, I
    always encourage my team to improve speed and quality of delivery by
    embracing agile, devops, continuous integration and continuous delivery.
  </Skill>
  <Skill name="People skills">
    I'm an experienced consultant, with skills in stakeholder management,
    influencing, facilitation, and training. As a team lead, I care deeply about
    the welfare of my team, and I try to make sure everyone's voice is heard,
    especially those from underrepresented or marginalised groups.
  </Skill>
</SkillBox>
