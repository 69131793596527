<script lang="ts">
  import Intro from './Intro.svelte';
  import Projects from './skillBoxes/Projects.svelte';
  import WebDev from './skillBoxes/WebDev.svelte';
  import MicroFrontends from './skillBoxes/MicroFrontends.svelte';
  import Practices from './skillBoxes/Practices.svelte';
</script>

<main class="w-full max-w-10xl my-0 mx-auto">
  <Intro />

  <Projects />

  <WebDev />

  <MicroFrontends />

  <Practices />

</main>
